<template>
	<OrthographiqWeeklyLeaderBoard
		v-if="formationProgress && formationProgress.config.topic_id.slug === 'orthographe'"
		:formationProgress="formationProgress"
		:profileInfos="profileInfos"
	/>
	<WeeklyLeaderBoardOtherTopic
		v-else-if="formationProgress && formationProgress.config.topic_id.slug !== 'orthographe'"
		:formationProgress="formationProgress"
		:profileInfos="profileInfos"
	/>
</template>

<script>
import { mapGetters } from 'vuex';
import OrthographiqWeeklyLeaderBoard from '@/components/weekly-leaderboard/OrthographiqWeeklyLeaderBoard.vue';
import WeeklyLeaderBoardOtherTopic from '@/components/weekly-leaderboard/WeeklyLeaderBoardOtherTopic.vue';

export default {
	name: 'WeeklyLeaderBoardPage',
	components: { OrthographiqWeeklyLeaderBoard, WeeklyLeaderBoardOtherTopic },
	computed: {
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			formationProgress: 'formationProgress',
		}),
	},
};
</script>

<style lang="scss" scoped></style>
